import * as React from 'react';
import {useEffect, useState} from 'react';
import {projectData, skillsData} from "../../../../database/data";
import {FirebaseProject} from "../../../../database/projectData";
import ProjectCardImgTop from "../components/projectCardImgTop";
import LoadIcon from "../../app/components/loadIcon";
import {SkillSearchResult} from "../../../../database/skillsData";
import SkillAndUsersCard from "../../app/skills/skillList/skillAndUsersCard";
import {Link} from "react-router-dom";

type LatestProjectsProps = {};
export const TopSkills: React.FunctionComponent<LatestProjectsProps> = ({}) => {
    const [skills, setSkills] = useState<SkillSearchResult>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        skillsData.getSkills(3).then((skills: SkillSearchResult) => {
            setSkills(skills)
            setLoading(false)
        })
    }, [])
    if (loading) return <LoadIcon/>
    return (
        <div className="flex flex-col mx-auto container justify-center">
            <div className="text-3xl text-center mt-3 px-2">Explore Popular Skills</div>
            <div className="mt-4 flex-grow overflow-y-hidden justify-center mx-5">
                {skills?.matches.map(skill => (
                    <SkillAndUsersCard skill={skill.skill} key={skill.skill.id}/>
                ))}
            </div>
            <div className="flex justify-center">
                <Link to="app/skills" className="text-center mt-1 mb-2 rounded-full hover:bg-gray-200 p-3 flex-shrink">
                    More Skills <div className="fas fa-chevron-right ml-2"></div>
                </Link>
            </div>
        </div>
    );
};