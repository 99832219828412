import authUser from "../auth/auth";
import {data} from "./data";
import firebase from "firebase";

export type Likes = {
    [email: string]: boolean
}

export enum LikeType {
    PROJECT = "projects",
    COMMENT = "comments"
}

export class LikesData {
    async changeLikedState(projectId: string, likeState: boolean, type: LikeType, email: string | undefined = authUser.getEmail()) {
        if (email) {
            let a = await data.getDatabase().collection(type.toString()).get()
            return await data.getDatabase().collection(type.toString())
                .doc(projectId).set({
                    likes: {[email]: likeState},
                    likeCount: firebase.firestore.FieldValue.increment(likeState ? 1 : -1)
                }, {merge: true})
        }
        return false
    }

    getLikesFromComment(project: Likes) {
        if (project) {
            return Object.keys(project).filter(key => project[key])
        } else {
            return []
        }
    }
}

for (const key of Object.getOwnPropertyNames(LikesData.prototype)) {
    const old = LikesData.prototype[key];
    if (old.constructor.name === "AsyncFunction") {
        LikesData.prototype[key] = async function (...args) {
            console.log('Fetching Data', key);
            return await old.call(this, ...args);
        };
    } else {
        LikesData.prototype[key] = function (...args) {
            console.log('Fetching Data', key);
            return old.call(this, ...args);
        };
    }

}

