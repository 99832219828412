import * as React from "react";
import {useEffect, useState} from "react";
import authUser from "../../../auth/auth";
import {Header} from "./parts/header";
import {LatestProjects} from "./parts/latestProjects";
import {TopSkills} from "./parts/topSkills";
import {YourLikedProjects} from "./parts/yourLikedProjects";
import {YourSubscribedProjects} from "./parts/yourSubscribedProjects";
import {MostLikedProjects} from "./parts/mostLikedProjects";
import LoadIcon from "../app/components/loadIcon";
import {userData} from "../../../database/data";
import FullHeightFirstTimeForm from "./parts/firstTimeForm";
import {Footer} from "./parts/footer";
import {SearchHome} from "./parts/searchHome";

export interface AppHomeProps {
}

export interface AppHomeState {
}

export const AppHome: React.FunctionComponent<AppHomeProps> = ({}) => {
    const loggedIn = !!authUser.getEmail()
    return (
        <div>
            <SearchHome/>
            {!loggedIn ?
                <Header color={"bg-red-100"} loggedIn={loggedIn}/> : null}
            <LatestProjects/>
            <TopSkills/>
            <MostLikedProjects/>
            {loggedIn ?
                <YourSubscribedProjects/> : null}
            {/*{loggedIn ?*/}
            {/*    <YourLikedProjects/> : null}*/}
            <Footer/>
        </div>
    );

};


export default AppHome;
// <Link
//     className="w-full md:w-1/4 max-w-xs rounded overflow-hidden shadow-lg mt-6 justify-center h-48 mx-3 px-3 flex flex-wrap content-center text-2xl"
//     to="/app/projects"
// >
//   View Projects
// </Link>
// <Link to="/app/skills" className="w-full md:w-1/4 max-w-xs rounded overflow-hidden shadow-lg mt-6 justify-center h-48 mx-3 px-3 flex flex-wrap content-center text-2xl">
//     View Skills
// </Link>