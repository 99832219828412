import * as React from "react";
import * as firebase from "firebase/app";
import "firebase/storage";
import { uuid } from 'uuidv4';
import { withAlert } from 'react-alert';
import imageCompression from 'browser-image-compression';

export interface ImageUploaderProps {
    setImage: Function;
    className?: string;
}

export interface ImageUploaderState {
}

/**
 * This class handles the image related file uploads to firebase
 */
class ImageUploader extends React.Component<ImageUploaderProps,
    ImageUploaderState> {

    state = {
        username: "",
        avatar: "",
        isUploading: false,
        progress: 0,
        avatarURL: "",
        image: null,
    };

    /*
    * This method is responsible for rendering action text in the ImageUploader component
    * It switches between 'Image Uploaded', 'Uploading...' and 'Add Image' depending on
    *   isUploading state.
    */
    uploadProgressText() {
        if (this.state.avatarURL) {
            return (
                <div>
                    Image Uploaded <i className="fas fa-check"/>
                </div>
            );
        } else if (
            this.state.isUploading
        ) {
            return <div>Uploading...</div>;
        } else {
            return (
                <div>
                    Add Image&emsp;
                    <i className="fas fa-plus"/>
                </div>
            );
        }
    }

    /*
    * This function gets called after a user has selected a file to upload
    * primarily checks for file size and ensure it is smaller than a specified size
    * calls file upload function if successful
    */
    handleFileAddedEvent = e => {
        try {
            let fileSizeBytes = e.target.files[0].size;
            let fileSizeMB = (fileSizeBytes / 1048576);
            const image = e.target.files[0]
            if(fileSizeMB > 20) {
                // @ts-ignore
                this.props.alert.show('The maximum image size for upload is 20MB');
            } else {
                this.setState({
                    isUploading: true,
                    progress: 0,
                    image: image
                })
                this.handleFileUpload(image)
            }
        } catch(err) {
            console.log(err)
        }
    }

    /*
    * This function handles image compression and upload to firebase, and then retrieving
    * the image URL for thumbnail display on Project/Skills components.
    */
    handleFileUpload = async (image: File) => {
        const compressionOptions = {
            maxSizeMB: 2,
            maxWidthOrHeight: 1920,
            useWebWorker: true,
            onProgress: () => {}
        }
        // @ts-ignore
        const compressedImage = await imageCompression(image, compressionOptions);
        // @ts-ignore
        let randomizedFileName = uuid()
        // @ts-ignore
        let fileExtension = image.name.split('.').pop();
        let uploadFileName = randomizedFileName + '.' + fileExtension
        // @ts-ignore
        const uploadTask = firebase.storage().ref(`images/${uploadFileName}`).put(compressedImage);
        uploadTask.on('state_changed',
            (snapshot) => {
                // upload in-progress callback
                const progress = Math.round((snapshot.bytesTransferred / snapshot.totalBytes) * 100);
                this.setState({progress});
            },
            (error) => {
                // upload failed callback
                console.log(error);
            },
            () => {
                // upload successful callback
                // @ts-ignore
                firebase.storage().ref('images').child(uploadFileName).getDownloadURL().then(url => {
                    this.setState({
                        avatarURL: url,
                        isUploading: false,
                        progress: 0
                    });
                    this.props.setImage(url);
                })
            });
    }
    render() {
        let backgroundColor = this.state.avatarURL
            ? "bg-green-700 hover:bg-green-800"
            : "bg-primary hover:bg-red-800";

        // This style block exists b/c html doesn't hide <input> tag naturally,
        // so the below is a custom CSS class to hide the <input> tag and provide
        // better UX
        const inputStyle = {
            width: '0.1px',
            height: '0.1px',
            opacity: '0',
            overflow: 'hidden',
            position: 'absolute',
            zIndex: '-1',
        }

        return (
            <label
                className={this.props.className||
                `text-white font-bold py-2 px-4 ml-auto block rounded-full cursor-pointer ${backgroundColor}`}
            >
                {this.uploadProgressText()}
                {/*
                // @ts-ignore*/}
                <input style={inputStyle} type="file" accept='image/*' onChange={this.handleFileAddedEvent}/>
            </label>
        )
    }
}

export default withAlert()(ImageUploader);