import * as React from 'react';
import {LogoAndText} from "../../../nav/logoAndText";
import {Link} from "react-router-dom";
import {FacebookInstagram} from "./facebookInstagram";
import authUser from "../../../../auth/auth";

type FooterProps = {

};
export const Footer: React.FunctionComponent<FooterProps> = ({}) => {

    function returnLogInSignup() {
        return [
            <Link to={"/login"} className="mr-4">Login</Link>,
            <Link to={"/login"} className="mr-4">Sign Up</Link>
        ]
    };

    return (
        <div className="flex flex-col items-center mx-auto mt-6">
            <LogoAndText/>
            <div className="mt-4">
                {authUser.getEmail() ? null : returnLogInSignup()}
                <Link to={"/app/skills"} className="mr-4">Skills</Link>
                <Link to={"/app/projects"}>Projects</Link>
            </div><div className="mt-4">
                <Link to={"/about"} className="mr-4">About Us</Link>
                <Link to={"/privacy"} className="mr-4">Privacy Policy</Link>
                <Link to={"/termsOfUse"} className="mr-4">Terms Of Use</Link>
                <Link to={"/cookiePolicy"} className="mr-4">Cookie Policy</Link>
            </div>
            <FacebookInstagram color={"text-black"}/>
        </div>
    );
};