import {data, userData} from "./data";
import firebase from "firebase";
import authUser from "../auth/auth";
import {Observable} from "rxjs";
import {Likes} from "./likesData";

type Timestamp = firebase.firestore.Timestamp;

export type UserProperties = {
    displayName: string,
    photoURL: string,
    email: string,
    subscriptions: {
        [email: string]: boolean
    },
    tagline: string,
    description: string,
    phoneNumber: string,
    createdAt?: Timestamp,
    university: string
}

export enum SubscriptionActionType {
    ADD,
    REMOVE
}
function timeout(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}
class UserData {
    getUserSubscriptions = (email: string = authUser.getEmail() || "") =>
        new Observable<string[]>((observer) => {
            if (email) {
                data
                    .getDatabase()
                    .collection("users")
                    .doc(email)
                    .onSnapshot((user) => {
                        let userData = {id: user.id, ...user.data()}
                        observer.next(this.getActiveSubscriptionsFromSubscriptions(userData.subscriptions))
                    });
            }
        })
    getNamesAndImage = async (peopleArray: string[]): Promise<{name: string, image: string}> => {
        const allUsersForChannel:(UserProperties|undefined)[] = await Promise.all(peopleArray.map(email=>userData.getUserFromEmail(email)))
        let name = allUsersForChannel.filter(x=>x).map(user=>userData.getUserName(user)).join(", ");
        let image = allUsersForChannel[0]?.photoURL||'/img/emptyprofile.svg'
        return {name, image}
    }
    async getIsFirstTime(email = authUser.getEmail()) {
        if (email) {
            let signUpSuccessful = (await data
                .getDatabase()
                .collection("users")
                .doc(email)
                .get()).data()
            while (!signUpSuccessful){
                signUpSuccessful = (await data
                    .getDatabase()
                    .collection("users")
                    .doc(email)
                    .get()).data()
                await timeout(1000)
            }
            if (signUpSuccessful && signUpSuccessful.signUpSuccessful) return !signUpSuccessful.signUpSuccessful
            return true
        }
        return false
    }

    async submitSignup(degree: string, gradYear: string, gender: string, displayName: string, university:string,
                       email = authUser.getEmail()) {
        if (email) {
            await data
                .getDatabase()
                .collection("users")
                .doc(email)
                .set({signUpSuccessful: true, degree, gradYear, gender, displayName, university}, {merge: true})
            return true
        }
        return false
    }

    getActiveSubscriptionsFromSubscriptions(subscriptions?: { [email: string]: boolean }): string[] {
        if (subscriptions) {
            return Object.keys(subscriptions).filter(key => subscriptions[key])
        } else {
            return []
        }
    }

    async addRemoveSubscription(subscriptionActionType: SubscriptionActionType, email: string, alert: any, authUserEmail: string = authUser.getEmail() || "") {
        if (email && authUserEmail) {
            let currentSubscriptions = (await data
                .getDatabase()
                .collection("users")
                .doc(authUserEmail)
                .get()).data()
            let lessThanTenCurrentSubscriptons = !currentSubscriptions.subscriptions || (currentSubscriptions.subscriptions && userData.getActiveSubscriptionsFromSubscriptions(currentSubscriptions.subscriptions).length < 10)
            if (lessThanTenCurrentSubscriptons || subscriptionActionType === SubscriptionActionType.REMOVE) {
                let setEmailTo = subscriptionActionType === SubscriptionActionType.ADD
                data
                    .getDatabase()
                    .collection("users")
                    .doc(authUserEmail)
                    .set({subscriptions: {[email]: setEmailTo}}, {merge: true})
            } else {
                alert.show("Currently, you can only subscribe to a maximum of 10 people.", {type: "error", timeout: 0})
            }
        }
    }

    async getUserFromEmail(email = authUser.getEmail()): Promise<UserProperties | undefined> {
        if (email) {
            const userData = await data
                .getDatabase()
                .collection("users")
                .doc(email)
                .get();
            return userData.data();
        }
        return undefined
    }

    async getUserListFromPartialEmail(partialEmail: string) {
        const userData = await data
            .getDatabase()
            .collection("users")
            .orderBy("email")
            .startAt(partialEmail)
            .endAt(partialEmail + "\uf8ff")
            .get();
        return userData.docs.map(doc => doc.data());
    }

    async setUserData(newData: Object, email = authUser.getEmail()) {
        if(email) {
            await data
                .getDatabase()
                .collection("users")
                .doc(email)
                .set(newData, {merge: true});
            return true
        }
        return false
    }

    async getTopUsers(limit: number) {
        let users = await data.getDatabase().collection("users").limit(limit).get()
        return users.docs.map((user) => ({id: user.id, ...user.data()}))
    }

    getUserName(userData) {
        return userData.displayName || userData.email;
    }
}

for (const key of Object.getOwnPropertyNames(UserData.prototype)) {
    const old = UserData.prototype[key];
    if (old.constructor.name === "AsyncFunction") {
        UserData.prototype[key] = async function (...args) {
            console.log('Fetching Data', key);
            return await old.call(this, ...args);
        };
    } else {
        UserData.prototype[key] = function (...args) {
            console.log('Fetching Data', key);
            return old.call(this, ...args);
        };
    }

}


export default UserData;
