import * as React from 'react';
import {useEffect, useState} from 'react';
import {projectData} from "../../../../database/data";
import {FirebaseProject, ProjectSortType} from "../../../../database/projectData";
import ProjectCardImgTop from "../components/projectCardImgTop";
import LoadIcon from "../../app/components/loadIcon";
import {Link} from "react-router-dom";

type LatestProjectsProps = {};
export const LatestProjects: React.FunctionComponent<LatestProjectsProps> = ({}) => {
    const [projects, setProjects] = useState<FirebaseProject[]>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        projectData.getLatestPosts(3, undefined, ProjectSortType.TIME).then((projects: FirebaseProject[]) => {
            setProjects(projects)
            setLoading(false)
        })
    }, [])
    if (loading) return <LoadIcon/>
    return (
        <div className="flex flex-col mx-auto container justify-center">
            <div className="text-3xl text-center mt-5 px-2">Explore Latest Projects</div>
            <div className="mt-4 flex flex-wrap overflow-y-hidden justify-center px-5">
                {projects?.map(project => (
                    <ProjectCardImgTop key={project.id} data={project}/>
                ))}
            </div>
            <div className="flex justify-center">
                <Link to="app/projects" className="text-center mt-1 mb-2 rounded-full hover:bg-gray-200 p-3 flex-shrink">More
                    Projects <div className="fas fa-chevron-right ml-2"></div>
                </Link>
            </div>
        </div>
    );
};