import * as React from "react";
import ProjectCard from "./projectCard";
import {projectData} from "../../../../database/data";
import LoadIcon from "../components/loadIcon";
import {debounce} from "../../../../database/utils";
import {ProjectSortType} from "../../../../database/projectData";

export interface ProjectListProps {
    user?: string;
    searchAlignment: SearchAlignment;
}

export interface ProjectListState {
    projects: Array<Object>;
    hasMore: boolean;
    loading: boolean;
    items: number;
    loadingSearch: boolean;
    loadingMore: boolean;
    sortType: ProjectSortType;
}

export enum SearchAlignment {
    Left = "mr-auto",
    Right = "ml-auto",
    Center = "mx-auto"
}

class ProjectList extends React.Component<ProjectListProps, ProjectListState> {
    state = {projects: [], hasMore: true, loading: true, items: 0, loadingSearch: false, loadingMore: false, sortType: ProjectSortType.TIME };

    componentDidMount() {
        this.loadMoreProjects();
    }

    loadMoreProjects = (add: number = 5) => {
        projectData.getLatestPosts(this.state.items + add, this.props.user ? this.props.user : "", this.state.sortType)
            .then(this.renderData);
        this.setState({items: this.state.items + add, loadingMore: true})
    };
    renderData = (data: Object[]) => {
        if (data.length !== this.state.items) {
            this.setState({hasMore: false})
        }
        this.setState({projects: data, loading: false, loadingSearch: false, loadingMore: false});
    };

    handleOnSearch = debounce((searchString) => {
        this.setState({items: 5}, () => {
            if (searchString.length > 0) {
                projectData.getPostSearchResults(searchString).then((data) => {
                    this.renderData(data)
                })
            } else {
                this.setState({hasMore: true})
                projectData.getLatestPosts(this.state.items, this.props.user ? this.props.user : "", this.state.sortType)
                    .then(this.renderData);
            }
        })
    }, 500)


    render() {
        if (this.state.loading) return <LoadIcon/>
        return (
            <div>
                {this.state.loadingSearch ?
                    <LoadIcon/> :
                    <div className="pb-3">
                        {this.state.projects.map((data) => (
                            <ProjectCard key={data["id"]} data={data}/>
                        ))}
                        {this.state.hasMore ? <button
                            onClick={() => this.loadMoreProjects()}
                            className="bg-blue-700 hover:bg-blue-dark text-white font-bold py-2 px-4 m-auto block rounded-full my-3"
                        >
                            {this.state.loadingMore ? <span>Loading...</span> : <span>Load More</span>}
                        </button> : false}
                    </div>
                }

            </div>

        )
    }
}

export default ProjectList;