import * as React from 'react';
import {useRef} from 'react';
import {CommentType, FirebaseComment} from "../../../../../database/commentData";
import {ProfileImgAndName} from "../profileImgAndName";
import {commentData} from "../../../../../database/data";
import authUser from "../../../../../auth/auth";
import {ChildComments} from "./childComments";
import {LikeType} from "../../../../../database/likesData";
import {LikeCounter} from "../likeCounter";

type CommentProps = {
    comment: FirebaseComment,
    reply: (email) => void,
    refresh: () => void,
};
export const Comment: React.FunctionComponent<CommentProps> = ({comment, reply, refresh}) => {
    const email = authUser.getEmail()
    const replyBox = useRef<any>()

    const replyClick = () => {
        if(comment.type == CommentType.CHILD){
            reply(email)
        } else {
            replyBox.current.focus()
        }
    }
    return (
        <div>
            <div
                className="p-5 sm:m-3 flex flex-col m-0 shadow-md overflow-auto break-word border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded">
                <div className="flex  justify-between">
                    <ProfileImgAndName email={comment.user_email} time={comment.date.toDate()}/>
                    <div className={`flex items-center h-4`}>
                        {email === comment.user_email ? <div onClick={async () => {
                            await commentData.deleteComment(comment.id)
                            refresh()
                        }} className="fas fa-trash mr-2 cursor-pointer text-black"/> : null}
                        <div onClick={replyClick} className="fas fa-reply mr-2 cursor-pointer text-black"/>
                        <LikeCounter className="h-5" initLikes={comment.likes} id={comment.id} type={LikeType.COMMENT}/>
                    </div>
                </div>
                <div className="break-all mt-4">{comment.text}</div>
            </div>
            {comment.type!==CommentType.CHILD?<ChildComments replyBox={replyBox} hasChildren={comment.hasChildren||false} parentCommentId={comment.id}/>:null}
        </div>
    );
};