import * as React from 'react';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";

type SearchHomeProps = {};
export const SearchHome: React.FunctionComponent<SearchHomeProps> = ({}) => {
    return (
        <div className="px-5">
            <div
                className="container mx-auto rounded-lg rounded my-6 mx-5 px-6 py-10 items-center flex xl:justify-between justify-center flex-wrap lg:flex-no-wrap"
                style={{
                    backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`,
                    backgroundSize: "cover",
                    backgroundPosition:"bottom"
                }}
            >
                <div className="flex flex-col cursor-pointer text-center xl:text-left justify-start items-center xl:items-start">
                    <div className="ml-0 lg:ml-8 text-white px-5 md:px-0">Utilise <b>your</b> skills, act on <b>your</b> ideas. Find what you’re looking for below:</div>
                    <div className="flex  flex-wrap justify-center">
                        <div
                            className="rounded-full w-32 mr-2 py-2 my-2 ml-2 md:ml-8 bg-white text-black text-center"
                            style={{opacity: 0.75}}
                            onClick={() => window.location.replace("/app/projects")}
                        >Projects
                        </div>
                        <div
                            className="rounded-full w-32 mx-2 py-2 my-2  bg-white text-black text-center"
                            style={{opacity: 0.75}}
                            onClick={() => window.location.replace("/app/skills")}
                        >Skills
                        </div></div>

                </div>
                <div className="flex pt-0 ml-0 md:ml-5 items-center">
                    <h1 className="text-white md:text-5xl text-4xl my-2  text-center neue">CONNECT. &nbsp;
                        <span style={{color: "#ec6763"}}><span className="font-extrabold">CREATE.</span></span>&nbsp; IDEATE.</h1>
                </div>
            </div>
        </div>
    );
};