import * as React from 'react';
import {useEffect, useState} from "react";
import firebase from "firebase";

type NotificationsProps = {

};
export const Notifications: React.FunctionComponent<NotificationsProps> = ({}) => {
    // const [notificationsOn, setNotificationsOn] = useState(false)
    // useEffect(()=>{
    //     // const messaging = firebase.messaging()
    //
    //     Notification.requestPermission().then(permission=>{
    //         if (permission === 'granted') {
    //             console.log('Notification permission granted.');
    //             setNotificationsOn(true)
    //         } else {
    //             console.log('Unable to get permission to notify.');
    //         }
    //     })
    // },[])
    // if(notificationsOn) return <i className="fas fa-bell"/>;
    // return <i className="far fa-bell"/>;
    return null;
};