import React, {Component} from "react";
import {BrowserRouter as Router, Redirect, Route} from "react-router-dom";
import Nav from "../components/nav/nav";
import Home from "../components/pages/home";
import About from "../components/pages/about";
import LoginPage from "../components/pages/login/loginPage";
import LoggedInRouter from "./LoggedInRouter";
import authUser from "../auth/auth";
import {transitions, positions, Provider as AlertProvider} from 'react-alert'
import AlertTemplate from 'react-alert-template-basic'
import LoadIcon from "../components/pages/app/components/loadIcon";
import store from 'store'
import AppHome from "../components/pages/frontPage/appHome";
import {isMobile} from "react-device-detect";
import PrivacyPolicy from "../components/pages/about/privacyPolicy";
import FullHeightAboutUs from "../components/pages/about/about";
import FullHeightTermsOfUse from "../components/pages/about/termsOfUse";
import FullHeightCookiePolicy from "../components/pages/about/cookiePolicy";

export const FullHeightContext = React.createContext()
const alertOptions = {
    // you can also just use 'bottom center'
    position: isMobile? positions.BOTTOM_CENTER: positions.MIDDLE,
    timeout: 5000,
    // you can also just use 'scale'
    transition: transitions.SCALE
}

class App extends Component {
    state = {loggedIn: "loading", fullHeight: false};

    componentDidMount() {
        authUser.onAuthStateChanged(this.checkIfLoggedIn);
    }

    checkIfLoggedIn = user => {
        console.log('loggedInChecked', Boolean(user))
        this.setState({loggedIn: Boolean(user)});
    };

    loggedInRoutes() {
        if (this.state.loggedIn) {
            return (
                <React.Fragment>
                    <Route path="/app" component={LoggedInRouter}/>
                    <Route
                        path="/login"
                        render={props => <Redirect {...props} to="/app"/>}
                    />
                </React.Fragment>
            );
        } else {
            return (
                <React.Fragment>
                    <Route path="/login" component={LoginPage}/>
                    <Route
                        path="/app"
                        render={props => <Redirect {...props} to="/login"/>}
                    />
                </React.Fragment>
            );
        }
    }

    render() {
        return (
            <FullHeightContext.Provider value={(fullHeight) => this.setState({fullHeight})}>
                <AlertProvider template={AlertTemplate} {...alertOptions}>
                    <div
                        className={`break-words flex flex-col h-full theme-light ${this.state.fullHeight ? "h-screen" : ""}`}>
                        <Router>
                            <Nav loggedIn={this.state.loggedIn}/>
                            {this.state.loggedIn !== "loading" ?
                                <>
                                    <Route exact path="/" component={AppHome}/>
                                    <Route path="/home" component={Home}/>
                                    <Route path="/privacy" component={PrivacyPolicy}/>
                                    <Route path="/termsOfUse" component={FullHeightTermsOfUse}/>
                                    <Route path="/cookiePolicy" component={FullHeightCookiePolicy}/>
                                    <Route path="/about" component={FullHeightAboutUs}/>
                                    {this.loggedInRoutes()}
                                </> : <LoadIcon className="py-3"/>
                            }
                        </Router>
                    </div>
                </AlertProvider>
            </FullHeightContext.Provider>

        );
    }
}

export default App;
