import * as React from 'react';

type FacebookInstagramProps = {
color?:string
    margin?:string
};
export const FacebookInstagram: React.FunctionComponent<FacebookInstagramProps> = ({color = "text-white", margin = "m-auto"}) => {
    return (
        <div className={`${margin} ${color} text-4xl`}>
            <a href={"https://www.facebook.com/actea.io"} className="fab fa-facebook-square mr-4"></a>
            <a href={"https://www.instagram.com/actea.io/"} className="fab fa-instagram"></a>
        </div>
    );
};