import * as React from 'react';
import {ProfileImgAndName} from "../components/profileImgAndName";
import {Comments} from "../components/comments/comments";
import {CommentType} from "../../../../database/commentData";
import {data, skillsData} from "../../../../database/data";

type TestimonialsProps = {
    match: { params: { email: string } }
    history: any
};
export const Testimonials: React.FunctionComponent<TestimonialsProps> = ({match, history}) => {
    return (
        <div className="container mx-auto p-5  flex flex-col">
            <div className="flex items-center mb-4 justify-center">
                <i className="fas fa-chevron-left mr-5 ml-2 cursor-pointer" onClick={() => history.goBack()} />
                <div className="text-center font-bold text-2xl">Testimonials</div>
            </div>
            <div className="flex items-center justify-center flex-shrink flex-col table self-center">
                <ProfileImgAndName email={match.params.email}/>
            </div>
            <div className="flex-grow">
                <Comments postId={match.params.email} commentType={CommentType.USER}/>
            </div>

        </div>
    );
};