import React, {useState, useEffect} from "react";
import {projectData, skillsData, userData} from "../../../../database/data";
import auth from '../../../../auth/auth'
import {Link} from "react-router-dom";
import AsyncCreatableSelect from 'react-select/async-creatable';
import makeAnimated from "react-select/animated";
import SkillsDisplay from "./skillsDisplay";
import {debounce} from "../../../../database/utils";
import {Skill} from "../../../../database/skillsData";
import { useAlert, positions } from 'react-alert'

export interface SkillsEditProps {
}

const animatedComponents = makeAnimated();

type SkillText = {
    label: string,
    value: string
}

const SkillsEdit: React.FunctionComponent<SkillsEditProps> = () => {
    const [skillText, setSkillText] = useState<SkillText>();
    const [newSkills, setNewSkills] = useState<Skill[]>([])
    const [loading, setLoading] = useState<boolean>(true);
    const alert = useAlert()

    useEffect(() => {
        skillsData.getSkillsForUser(auth.getEmail()).then((skills) => {
            setNewSkills(skills);
            setLoading(false);
        });
    }, []);

    const promiseOptions = debounce((inputValue, callback) => {
        let projects = skillsData.getSkills(10, inputValue);
        projects.then((projects) => {
            callback(
                projects.matches.map(project => ({
                    value: project.skill.name,
                    label: <div className="capitalize">{project.skill.name}</div>
                }))
            )
        })
    }, 0);

    function handleAddSkills() {
        // @ts-ignore
        skillsData.createNewSkill(skillText.value).then(skill => {
            if (skill) {
                setNewSkills([skill, ...newSkills])
            } else {
                alert.show('Skill Already Here')
            }
        })

        // reset setSkillText hook state, value set to undefined so placeholder is re-rendered
        setSkillText(undefined)
    };

    return <div className="text-center m-auto container flex-grow">
        <div className="mx-auto mb-6 text-center mt-12 font-bold text-xl p-3">
            What skills would you love to share with the world?
        </div>
        <div className="flex flex-wrap mb-6 flex-col items-center">
            <div className="w-full md:w-1/2 px-3  md:mb-0">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Skill
                </label>
                <AsyncCreatableSelect
                    components={{ DropdownIndicator:() => null, animatedComponents }}
                    ref={input => input && input.focus()}
                    className=""
                    closeMenuOnSelect={true}
                    cacheOptions
                    backspaceRemovesValue={true}
                    placeholder={'Search for a role'}
                    isClearable ={true}
                    value={skillText ? skillText : null}
                    onChange={setSkillText}
                    noOptionsMessage={() => null}
                    loadOptions={promiseOptions}
                />
                {/*
                // @ts-ignore */}
                {skillText?.value ?
                    <button
                        onClick={handleAddSkills}
                        className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded mt-12"
                    >
                    Add Skill
                    </button> : null}
            </div>
            <div className="w-full md:w-1/2 px-3 mb-6 md:mb-0 mt-6 text-left">
                <label className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">
                    Your Current Skills
                </label>
                <SkillsDisplay
                    newSkills={newSkills}
                    email={auth.getEmail() || ""}
                    editing={true}
                    isLoading={loading}
                />
            </div>

        </div>
    </div>;
};


export default SkillsEdit;
