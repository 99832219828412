import * as React from "react";
import {Component} from "react";
import {messageData, userData} from "../../../../database/data";
import authUser from "../../../../auth/auth";
import ProfileImg from "../components/profileImg";
import {Link} from "react-router-dom";
import UserData, {UserProperties} from "../../../../database/userData";

export interface MessengerContactProps {
    channelId;
    search: string;
}

export interface MessengerContactState {
}

class MessengerContact extends React.Component<MessengerContactProps,
    MessengerContactState> {
    state = {image: "load", name: "", lastMessage: ""};

    componentDidMount() {
        messageData.getChannelInfoFromFirebase(
            this.props.channelId,
            this.renderChannelInfo
        );
    }

    renderChannelInfo = channelInfo => {
        console.log(channelInfo);
        let peopleArray: Array<string> = Object.entries(channelInfo.people)
            .filter(([_, v]) => v)
            .map(x => x[0]);
        this.setState({lastMessage: channelInfo.lastMessage});
        peopleArray = peopleArray.filter(item => item !== authUser.getEmail());
        let name = peopleArray.join(", ");
        userData.getNamesAndImage(peopleArray).then(nameAndImage => this.setState({
            name: nameAndImage.name,
            image: nameAndImage.image
        }))
    };

    renderUserData = data => {
        this.setState({
            image: data.photoURL
        });
    };

    render() {
        if (this.props.search && !this.state.name.toLowerCase().includes(this.props.search.toLowerCase())) {
            return false
        }
        return (
            <Link to={`/app/messenger/channel/${this.props.channelId}`}>
                <div className="flex">
                    <ProfileImg
                        picClasses={`h-16 w-16 rounded-full mx-auto flex-no-shrink`}
                        img={this.state.image}
                    />
                    <div className="p-4 overflow-hidden">
                        <div className="font-bold text-xl mb-2">{this.state.name}</div>
                        <p className="text-gray-700 text-base truncate break-words">{this.state.lastMessage}</p>
                    </div>
                </div>
            </Link>
        );

    }
}

export default MessengerContact;
