import * as React from 'react';
import {useEffect} from 'react';
import {FacebookInstagram} from "../frontPage/parts/facebookInstagram";
import {FullHeightContext} from "../../../routes/Routes";

type PrivacyPolicyProps = {
    fullHeightFunction;
};
const FullHeightPrivacyPolicy = () => {
    return (
        <FullHeightContext.Consumer>
            {fullHeightFunction => (
                <PrivacyPolicy fullHeightFunction={fullHeightFunction} />
            )}
        </FullHeightContext.Consumer>
    );
};
const PrivacyPolicy: React.FunctionComponent<PrivacyPolicyProps> = ({fullHeightFunction}) => {
    useEffect(()=>{
        fullHeightFunction(true);
        return () => fullHeightFunction(false);
    },[])
    return (
        <div
            className="h-full flex flex-col overflow-auto"
            style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`,
                backgroundSize: "cover"
            }}
        >
            <div className="flex-grow w-full flex md:w-3/5 py-6 text-center m-auto px-0 md:px-6">
                <div
                    className="overflow-hidden flex flex-grow shadow-lg"
                    style={{ backgroundColor: "#ec6763", borderRadius: "4em" }}
                >
                    <div
                        className="flex-col rounded flex-grow overflow-hidden shadow-lg bg-white flex text-black my-6 mx-0 md:mx-12 pt-12"
                        style={{ textAlign: "left", borderRadius: "6em", transform: "translateZ(0)" }}
                    >
                        <div className="text-center text-4xl font-bold ">Privacy Policy</div>
                        <iframe className="mt-5 flex-grow" src="https://drive.google.com/file/d/1OA3SpjuDQTGL7sbmafxtw92MXR3q9-E3/preview" width="100%"/>
                    </div>
                </div>
            </div>
            <FacebookInstagram />
        </div>
    );
};

export default FullHeightPrivacyPolicy