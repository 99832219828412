import * as React from 'react';
import ProfileImg from "./profileImg";
import {messageData, userData} from "../../../../database/data";
import Moment from "react-moment";
import {useEffect, useState} from "react";
import {User} from "firebase";
import {UserProperties} from "../../../../database/userData";
import LoadIcon from "./loadIcon";
import {Link} from "react-router-dom";
import {SubscribeButton} from "./subscribeButton";
import authUser from "../../../../auth/auth";

type ProfileImgAndNameProps = {
    email: string,
    time?: Date,
    emailShown?: boolean,
    allowMessage?: boolean
};
export const ProfileImgAndName: React.FunctionComponent<ProfileImgAndNameProps> = ({email, time, emailShown, allowMessage=false}) => {
    const [user, setUser] = useState<UserProperties>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        userData.getUserFromEmail(email).then((user) => {
            setUser(user)
            setLoading(false)
        })
    }, [])
    if (loading || !user) return <LoadIcon className="w-10 mr-auto"/>

    const messageBtnStyle = {
        cursor: 'pointer',
        color: 'rgb(30, 30, 30)',
        fontSize: '0.75rem',
    }

    const messagePerson = async () => {
        let myEmail: string | undefined = authUser.getEmail();
        let receiverEmail: string | undefined = email;
        if (myEmail && receiverEmail) {
            let newChat = await messageData.createNewChannel([myEmail, receiverEmail]);
            window.location.replace(`/app/messenger/channel/${newChat}`);
        }
    }

    return (
            <div className="flex items-center">
                <Link to={`/app/profile/email/${email}`}>
                <ProfileImg img={user?.photoURL}/>
                </Link>
                <div className="text-sm break-all">
                    <Link to={`/app/profile/email/${email}`}>
                    <div className="text-black leading-none flex flex-wrap block sm:hidden">
                        {userData.getUserName(user).split(' ')[0]}
                    </div>
                    <div className="text-black leading-none flex flex-wrap hidden sm:block">
                        {userData.getUserName(user)}&nbsp;{emailShown ?
                        <div className="hidden sm:block">{`(${email})`}</div> : null}
                    </div>

                    {time ?
                        <Moment fromNow className="">
                            {time.toUTCString()}
                        </Moment>
                        : false
                    }
                    </Link>
                    <SubscribeButton email={email} className="hidden sm:block"/>
                    {allowMessage ?
                        <div style={messageBtnStyle} onClick={messagePerson}>
                            <i
                               className="fas fa-comments">
                            </i>
                            &ensp;Message
                        </div>
                        : null
                    }
                </div>
            </div>
    );
};