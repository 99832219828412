import * as React from 'react';
import {useEffect, useState} from 'react';
import {commentData} from "../../../../database/data";
import {CommentType} from "../../../../database/commentData";

type TestimonialNumberProps = {
    email: string,
    className?:string
};
export const TestimonialNumber: React.FunctionComponent<TestimonialNumberProps> = ({email, className}) => {
    const [testimonialsNum, setTestimonialNum] = useState()
    useEffect(()=>{
        commentData.getComments(email, CommentType.USER).then(comments=>setTestimonialNum(comments.length))
    },[])
    return (
        <span className={className}>{testimonialsNum}</span>
    );
};