import * as React from 'react';
import {useEffect, useState} from 'react';
import {userData} from "../../../../database/data";
import {SubscriptionActionType} from "../../../../database/userData";
import authUser from "../../../../auth/auth";
import { useAlert } from 'react-alert'

type SubscribeButtonProps = {
    email:string
    className: string
};
export const SubscribeButton: React.FunctionComponent<SubscribeButtonProps> = ({email, className}) => {
    const [subscribed, setSubscribed] = useState(false)
    const alert = useAlert()
    useEffect(()=>{
        userData.getUserSubscriptions().subscribe(subscribedUsers=>{
            if(subscribedUsers.indexOf(email) >= 0){
                setSubscribed(true)
            } else {
                setSubscribed(false)
            }
        })
    },[])

    const subscribe = (subscriptionActionType: SubscriptionActionType) => {
        userData.addRemoveSubscription(subscriptionActionType, email, alert)
    }
    if(!authUser.getEmail())
        return null
    if(!subscribed)
    return (
        <div className={`text-black leading-none flex flex-wrap ${className}`} onClick={()=>subscribe(SubscriptionActionType.ADD)}>
            <button
                className="mt-1 bg-transparent text-xs text-blue-700 font-semibold hover:border-b-2 rounded-full">
                <i className="fas fa-plus"></i><span className="ml-2">Subscribe</span>
            </button>
        </div>
    );
    else{
        return(<div className={`text-black leading-none flex flex-wrap ${className}`} onClick={()=>subscribe(SubscriptionActionType.REMOVE)}>
            <button
                className="mt-1 bg-transparent text-xs text-green-700 font-semibold hover:border-b-2 rounded-full">
                <i className="fas fa-check"></i><span className="ml-2">Subscribed</span>
            </button>
        </div>);
    }
};