import * as React from 'react';
import {useEffect, useState} from 'react';
import {projectData} from "../../../../database/data";
import {FirebaseProject, ProjectSortType} from "../../../../database/projectData";
import ProjectCardImgTop from "../components/projectCardImgTop";
import LoadIcon from "../../app/components/loadIcon";
import {Link} from "react-router-dom";
import {ProjectsFilter} from "../../app/projectList/projectListImgTop";

type YourSubscribedProjectsProps = {};
export const YourSubscribedProjects: React.FunctionComponent<YourSubscribedProjectsProps> = ({}) => {
    const [projects, setProjects] = useState<FirebaseProject[]>()
    const [loading, setLoading] = useState(true)
    useEffect(() => {
        projectData.getLatestPosts(6, undefined, ProjectSortType.TIME, ProjectsFilter.SUBSCRIPTION).then((projects: FirebaseProject[]) => {
            setProjects(projects)
            setLoading(false)
        })
    }, [])
    if (loading) return <LoadIcon/>
    if(projects?.length == 0) return null
    return (
        <div className="flex flex-col mx-auto container justify-center">
            <div className="text-3xl font-bold text-center mt-5">Your Subscriptions</div>
            <div className="mt-4 flex flex-wrap overflow-y-hidden justify-center mx-5">
                {projects?.map(project => (
                    <ProjectCardImgTop key={project.id} data={project}/>
                ))}
            </div>
            <div className="flex justify-center">
                <Link to="app/projects" className="text-center mt-1 mb-2 rounded-full hover:bg-gray-200 p-3 flex-shrink">
                    More Projects <div className="fas fa-chevron-right ml-2"></div>
                </Link>
            </div>
        </div>
    );
};