import * as React from "react";
import { useEffect, useState } from "react";
import { FullHeightContext } from "../../../../routes/Routes";
import { userData } from "../../../../database/data";
import authUser from "../../../../auth/auth";
import LoadIcon from "../../app/components/loadIcon";
import { ProfilePhotoWithUploader } from "../../app/profile/profilePhotoWithUploader";
import { FacebookInstagram } from "./facebookInstagram";
import {Link} from "react-router-dom";

type FirstTimeFormProps = {
  fullHeightFunction;
};
const FullHeightFirstTimeForm = () => {
  return (
    <FullHeightContext.Consumer>
      {fullHeightFunction => (
        <FirstTimeForm fullHeightFunction={fullHeightFunction} />
      )}
    </FullHeightContext.Consumer>
  );
};
const FirstTimeForm: React.FunctionComponent<FirstTimeFormProps> = ({
  fullHeightFunction
}) => {
  // const [uData, setUData] = useState<UserProperties | {}>()
  const [name, setName] = useState("");
  const [photoUrl, setPhotoUrl] = useState("");
  const [university, setUniversity] = useState("");
  const [loading, setLoading] = useState(true);
  const [degree, setDegree] = useState("");
  const [gradYear, setGradYear] = useState("");
  const [gender, setGender] = useState("");
  const [tosAgree, setTosAgree] = useState(false);
  useEffect(() => {
    fullHeightFunction(true);
    userData.getUserFromEmail().then(uData => {
      if (uData) {
        // setUData(uData)
        setName(uData.displayName);
        setPhotoUrl(uData.photoURL);
      }
      setLoading(false);
    });
    return () => fullHeightFunction(false);
  }, []);

  const continueFromFirstStart = async () => {
    await userData.submitSignup(degree, gradYear, gender, name, university);
    window.location.replace(`/app/profile/email/${authUser.getEmail()}`);
  };

  if (loading) return <LoadIcon />;
  return (
    <div
      className="h-full flex flex-col overflow-auto"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`,
        backgroundSize: "cover"
      }}
    >
      <div className="flex-grow w-full md:w-3/5 py-6 text-center m-auto px-0 md:px-6">
        <div
          className="overflow-hidden shadow-lg"
          style={{ backgroundColor: "#ec6763", borderRadius: "4em" }}
        >
          <div
            className="rounded overflow-hidden shadow-lg bg-white text-black my-6 mx-0 md:mx-12 py-12 px-4 md:px-12 "
            style={{ textAlign: "left", borderRadius: "6em" }}
          >
            <div className="font-bold text-center md:text-left text-2xl mt-3">
              Welcome!
              <br />
              <br />
            </div>
            {authUser.getEmail()?.includes(".edu") ? (
              <>
                <div className="flex flex-col justify-center">
                  <ProfilePhotoWithUploader
                    email={authUser.getEmail()}
                    photoURL={photoUrl}
                  />
                  <div className="ml-4">Name (required)</div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    id="full-name"
                    type="text"
                    value={name}
                    onChange={e=>{let text= e.target.value.split(" ").map(x=>x.charAt(0).toUpperCase() +x.substring(1)).join(' ')
                      setName(text)}}
                    placeholder="eg. Elizabeth Blackburn"
                  />
                  <div className="ml-4 pt-3">Degree/Occupation (required)</div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    value={degree}
                    onChange={e => setDegree(e.target.value)}
                    placeholder="eg. Finance/IT"
                  />
                  <div className="ml-4 pt-3">University (required)</div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    value={university}
                    onChange={e => setUniversity(e.target.value)}
                    placeholder="eg. Monash University"
                  />
                  <div className="ml-4 pt-3">Graduate Year</div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    value={gradYear}
                    placeholder="eg. 2022"
                    onChange={e => setGradYear(e.target.value)}
                  />
                  <div className="ml-4 pt-3">Gender</div>
                  <input
                    className="bg-gray-200 appearance-none border-2 border-gray-200 rounded-full w-full py-2 px-4 text-gray-700 leading-tight focus:outline-none focus:bg-white focus:border-purple-500"
                    type="text"
                    value={gender}
                    placeholder="eg. Male/Female/Other"
                    onChange={e => setGender(e.target.value)}
                  />
                  <label className="flex items-center mt-6 ml-4">
                      <input type="checkbox" className="form-checkbox mr-4" style={{transform:"scale(2)"}} checked={tosAgree} onChange={e=>setTosAgree(e.target.checked)}/>
                      <span className="ml-2">I agree to the <Link to={"/privacy"} className="underline">Privacy Policy</Link>, <Link to={"/termsOfUse"} className="underline">Terms of Use</Link> and <Link to={"/cookiePolicy"} className="underline">Cookie Policy</Link></span>
                  </label>
                </div>
                <div className="mx-auto mt-12 text-center">
                  Act-ea.
                  <span className="font-bold ml-1 mr-1" style={{ color: "#ec6763" }}>
                    ACT
                  </span>
                  on your ideas
                </div>
                {(tosAgree && name && degree && university)?<button
                  id="submit"
                  style={{ backgroundColor: "#ec6763" }}
                  className="mx-auto block mt-3 hover:bg-blue-700 text-white font-bold py-2 px-4
							rounded-full"
                  onClick={continueFromFirstStart}
                >
                  Continue
                </button>:<div className="text-center mt-3">Please fill your name, degree, university and agree to the terms to continue.</div>}
              </>
            ) : (
              <>
                <div className="flex flex-col justify-center">
                  <div className="ml-4 text-2xl">Please Use a .edu Email To Sign In.</div>
                  <div className="ml-4 mt-4">We are currently limiting our users to university students. Please sign in with your student account to confirm.</div>
                </div>
                <button
                  id="submit"
                  style={{ backgroundColor: "#ec6763" }}
                  className="mx-auto block mt-3 hover:bg-blue-700 text-white font-bold py-2 px-4
							rounded-full"
                  onClick={()=>authUser.signOut()}
                >
                  Log Out
                </button>
              </>
            )}
          </div>
        </div>
      </div>
      <FacebookInstagram />
    </div>
  );
};
export default FullHeightFirstTimeForm;
