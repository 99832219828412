import * as React from 'react';
import {useState} from 'react';
import {likesData, projectData} from "../../../../database/data";
import authUser from "../../../../auth/auth";
import {Likes, LikeType} from "../../../../database/likesData";

type LikesProps = {
    initLikes: Likes,
    id:string,
    type: LikeType,
    className?: string
};
export const LikeCounter: React.FunctionComponent<LikesProps> = ({initLikes, id, type, className}) => {
    const [likes, setLikes] = useState<Likes>(initLikes)

    const setLikedState = () => {
        let email = authUser.getEmail()
        if(email && likes) {
            let newLikedStateForEmail = !likes[email]
            setLikes({...likes, [email]:newLikedStateForEmail})
            likesData.changeLikedState(id, newLikedStateForEmail,type)
        }
    }

    const getColor = () => {
        let email = authUser.getEmail()
        if(email && likes) {
            if(likes[email]) {
                return "text-teal-700"
            } else {
                return ""
            }
        }
        return ""
    }
    return (
            <div className={`flex items-center ${className} ${getColor()}`}>
                <div style={{marginTop: "5px"}}>{likesData.getLikesFromComment(likes).length}</div>
                <div onClick={setLikedState} className="fas fa-thumbs-up ml-2 cursor-pointer"/>
            </div>
    );
};