import * as React from "react";
import {RouteComponentProps} from "react-router";
import Linkify from 'react-linkify';
import {projectData} from "../../../../../database/data";
import LoadIcon from "../../components/loadIcon";
import {Comments} from "../../components/comments/comments";
import {CommentType} from "../../../../../database/commentData";
import {ProfileImgAndName} from "../../components/profileImgAndName";
import {FirebaseProject} from "../../../../../database/projectData";
import {LikeCounter} from "../../components/likeCounter";
import {LikeType} from "../../../../../database/likesData";
import authUser from "../../../../../auth/auth";
import {EditProject} from "./editProject";

export interface ProjectProps {
    history: any
}

export interface ProjectState {
    projectData?: FirebaseProject;
    loaded: boolean;
    editing: boolean
}

class Project extends React.Component<ProjectProps & RouteComponentProps<any>,
    ProjectState> {
    state: ProjectState = {loaded: false, projectData: undefined, editing: false};

    componentDidMount() {
        this.getUserProjectData();
    }

    getUserProjectData() {
        projectData.getProject(this.props.match.params.id).then((projectData: any) => {
            console.log(projectData)
            this.setState({projectData, loaded: true});
        });
    }

    render() {
        if (!this.state.loaded) return <LoadIcon className="pt-6"/>
        if (!this.state.projectData) return <span>No Project Found</span>
        return (
            <div className="container mx-auto p-8">
                <div className="flex items-center mb-8 mt-6 m-auto pl-3"
                     onClick={() => this.props.history.goBack()}>
                    <i className="fas fa-chevron-left mr-5 ml-2 cursor-pointer"/>
                    <label
                        className="block uppercase tracking-wide text-center text-gray-700 text font-bold">
                        Project Description
                    </label>
                </div>
                <div className="pb-8 flex flex-col items-center">
                    {this.state.projectData?.id ? <div className="">
                        <img
                            className="rounded-lg mx-auto border border-2 img-scale"
                            src={this.state.projectData?.image}
                        />
                    </div> : false}
                    {this.state.projectData?.user_email === authUser.getEmail() ? <div className="flex">
                        <button
                            onClick={() => this.setState({editing: true})}
                            className="m-2 bg-transparent hover:bg-teal-500 text-teal-700 font-semibold hover:text-white py-2 px-4 border border-teal-500 hover:border-transparent rounded-full">
                            <i className="fa fa-pencil-alt mr-2"></i> Edit
                        </button>
                        <button
                            className="m-2 bg-transparent hover:bg-orange-500 text-orange-700 font-semibold hover:text-white py-2 px-4 border border-orange-500 hover:border-transparent rounded-full"
                            onClick={() => {
                                if (window.confirm("Are you sure you would like to delete this project?")) {
                                    projectData.deleteProject(this.state.projectData?.id)
                                    window.history.back()
                                }
                            }}>
                            <i className="fa fa-trash mr-2"></i> Delete
                        </button>
                    </div> : null}
                    {this.state.editing ?
                        <EditProject projectId={this.state.projectData?.id} currentTitle={this.state.projectData?.title}
                                     currentDesc={this.state.projectData?.content} afterSave={() => {
                            this.setState({editing: false});
                            this.getUserProjectData()
                        }}/> :
                        <div
                            className="rounded-lg border flex-grow border-grey-500 shadow-md flex-grow p-2 w-full max-w-4xl mt-5">
                            <div className="uppercase tracking-wide text-sm text-blue-600 font-bold p-3 text-lg">
                                {this.state.projectData?.title}
                            </div>
                            <Linkify>
                                <p className="text-gray-600 p-3 project">
                                    {this.state.projectData?.content}
                                </p>
                            </Linkify>
                            <div className="flex items-center p-3">
                                <ProfileImgAndName email={this.state.projectData?.user_email}
                                                   time={this.state.projectData?.time.toDate()}/>
                                <LikeCounter className="ml-5" initLikes={this.state.projectData?.likes || {}}
                                             id={this.state.projectData?.id} type={LikeType.PROJECT}/>
                            </div>
                        </div>}
                </div>
                <Comments commentType={CommentType.PROJECT} postId={this.props.match.params.id}/>
            </div>
        );
    }
}

export default Project;
