import * as React from 'react';
import {useEffect, useState} from 'react';
import {SkillSearchResult, Skill} from "../../../../../database/skillsData";
import {skillsData} from "../../../../../database/data";
import LoadIcon from "../../components/loadIcon";
import SkillAndUsersCard from "./skillAndUsersCard";
import {debounce} from "../../../../../database/utils";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faToolbox} from "@fortawesome/free-solid-svg-icons/faToolbox";
import {ProjectSortType} from "../../../../../database/projectData";
import {ProjectsFilter} from "../../projectList/projectListImgTop";
import {Link} from "react-router-dom";
import authUser from "../../../../../auth/auth";

type SkillListProps = {};
export const SkillList: React.FunctionComponent<SkillListProps> = ({}) => {
    const [limit, setLimit] = useState<number>(10)
    const [searchResult, setSearchResult] = useState<SkillSearchResult>()
    const [loading, setLoading] = useState(true)
    const [noMore, setNoMore] = useState(false)
    const [searchTerm, setSearchTerm] = useState("")
    const [loadingMore, setLoadingMore] = useState(false)
    const [loadingSearch, setLoadingSearch] = useState(false)

    useEffect(() => {
        getData()
    }, [searchTerm])

    const getData = async (limit: number = 10) => {
        skillsData.getSkills(limit, searchTerm).then(result => {
            if (limit !== result.matches.length) {
                setNoMore(true)
            } else {
                setNoMore(false)
            }
            result.matches = result.matches.filter(skill =>
                skillsData.filterUsersWithSkill(skill.skill).length > 0)
            setSearchResult(result)
            setLoading(false)
            setLoadingSearch(false)
            setLoadingMore(false)
        })
    }

    const searchTermStateDebounce = debounce((text) => {
        setSearchTerm(text)
    }, 500)

    const searchText = (text) => {
        setLoadingSearch(true)
        setNoMore(true)
        searchTermStateDebounce(text)
    }

    if (loading) return <LoadIcon/>

    const loadMoreSkills = () => {
        if (searchResult) {
            setLoadingMore(true)
            getData(limit + 10)
            setLimit(limit + 10)
        }
    }

    return (
        <div className="flex flex-col">
            <div className="container mx-auto px-5">
                <div
                    className="rounded-lg mx-auto rounded mt-6 items-center flex px-12 flex-col md:flex-row"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`
                    }}
                >
                    <div className="flex py-3 items-center">
                        <FontAwesomeIcon
                            icon={faToolbox}
                            className="mr-6 text-white sm:text-5xl text-3xl"
                        />
                        <h1 className="text-white sm:text-6xl text-3xl">Skills</h1>
                    </div>
                    <div className="flex w-full items-center flex-wrap pl-0 md:pl-12  py-3">
                        <input type="text"
                               className={`border-2 py-2 px-4 m-3 flex-grow block rounded rounded-lg w-full`}
                               name="title"
                               onChange={(e) => {
                                   searchText(e.target.value)
                               }}
                               placeholder={'I\'d like some help with...'}/>
                    </div>

                </div>
            </div>
            <Link
                to={"/app/profile/editMySkills"}
                className="bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border-b border-l border-r border-red-500 hover:border-transparent shadow-md rounded-b-lg mx-auto self-center ">
                <i className="fa fa-plus mr-2"></i> Add My Skills
            </Link>
            <div>
                {loadingSearch ? <LoadIcon/> :
                    searchResult?.matches
                        .map((match) => <SkillAndUsersCard key={match.skill.id} skill={match.skill}/>)
                }
                {noMore ? false : <button
                    onClick={loadMoreSkills}
                    className="bg-blue-700 hover:bg-blue-dark text-white font-bold py-2 px-4 m-auto block rounded-full my-3"
                >
                    {loadingMore ? <span>Loading...</span> : <span>Load More</span>}
                </button>}
            </div>

        </div>
    );
};