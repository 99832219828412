import * as React from 'react';
import {Link} from "react-router-dom";
import authUser from "../../../../auth/auth";
import {FacebookInstagram} from "./facebookInstagram";

type HeaderProps = {
    loggedIn: boolean,
    color?: string
};
export const Header: React.FunctionComponent<HeaderProps> = ({loggedIn, color = "bg-white"}) => {
    const buttonLinks = {
        get getStarted() {
            if (loggedIn) return `/app/profile/email/${authUser.getEmail()}`
            else return "/login"
        }
    }
    return (
        <section className="px-5">
            <div className={`text-left px-5  py-8  justify-center my-8 rounded rounded-lg container mx-auto`}
                     style={{background: "#EC6763"}}>
                <div className="flex flex-col sm:flex-row justify-between text-white">
                    <div className="flex flex-col justify-center flex-grow ml-0 sm:ml-8 my-1 neue">
                        <div className="text-5xl">
                            ACT ON <span className="text-black">YOUR</span> IDEAS.
                        </div>
                        <div className="text-5xl">
                            ACT - EA.
                        </div>
                        <div className="text-5xl">
                            <span className="text-black">ACTEA</span>.
                        </div>
                    </div>
                    <div
                        className="flex flex-col items-center justify-center items-center w-full sm:w-1/4 mr-0 sm:mr-8 my-3">
                        <Link to={buttonLinks.getStarted} className="w-full">
                            <button
                                className="block bg-transparent w-full mb-4 bg-red-400 text-white font-semibold hover:text-white py-2 px-4 border-red-700 hover:border-transparent rounded-full">
                                Sign Up
                            </button>
                            <button
                                className="block bg-transparent w-full mb-4 bg-red-400 text-white font-semibold hover:text-white py-2 px-4 border-red-700 hover:border-transparent rounded-full">
                                Log In
                            </button>
                        </Link>
                        <FacebookInstagram margin=""/>

                    </div>
                </div>
            </div></section>

    );
};