import * as React from 'react';
import {useEffect, useRef, useState} from 'react';
import {CommentType, FirebaseComment} from "../../../../../database/commentData";
import {commentData} from "../../../../../database/data";
import LoadIcon from "../loadIcon";
import {Comment} from "./comment";

type ChildCommentsProps = {
    parentCommentId: string,
    hasChildren: boolean,
    replyBox: any
};
export const ChildComments: React.FunctionComponent<ChildCommentsProps> = ({parentCommentId, hasChildren, replyBox}) => {
    const [comments, setComments] = useState<FirebaseComment[]>()
    const [hasChildrenState, setHasChildrenState] = useState(hasChildren)
    const [loading, setLoading] = useState(false)
    const [showAll, setShowAll] = useState(false)
    const [commentBox, setCommentBox] = useState("")
    const [opacity, setOpacity] = useState(1)


    const refreshComments = () => {
        setOpacity(0.5)
        commentData.getComments(parentCommentId, CommentType.CHILD).then((comments) => {
            setOpacity(1)
            setComments(comments)
            setLoading(false)
        })
    }
    const repliesButton = () => {
        if (hasChildrenState) {
            return <div onClick={()=>{
                refreshComments()
                setLoading(true)
                setShowAll(true)
            }} className="items-center flex justify-center text-gray-500 hover:text-gray-700 cursor-pointer">
                Show Replies <div className="fas fa-chevron-down ml-2"></div>
            </div>
        }
        return null
    }
    if (loading) return <LoadIcon/>
    return (
        <div style={{opacity}} className="ml-20">
            {showAll ?
                comments?.map(comment => {
                    return <Comment key={comment.id} comment={comment} reply={(email) => {
                        setCommentBox(`+${email} ${commentBox}`);
                        replyBox.current.focus()
                    }} refresh={refreshComments}/>
                }) : repliesButton()
            }
            <div className="flex mr-5 ml-5">
                <div className="border-b border-b-2 flex-grow mr-3 border-teal-500  ">
                    <input
                        ref={replyBox}
                        value={commentBox}
                        onChange={(e) => {
                            setCommentBox(e.target.value)
                        }}
                        className="appearance-none focus:bg-gray-100 bg-transparent border-none w-full text-gray-700 mr-3 py-2 px-1 leading-tight focus:outline-none"
                        type="text" placeholder="Reply Here..."/>
                </div>
                <div
                    onClick={async () => {
                        setCommentBox("")
                        setOpacity(0.5)
                        await commentData.newComment(commentBox, parentCommentId, CommentType.CHILD)
                        setOpacity(1)
                        setHasChildrenState(true)
                        setShowAll(true)
                        refreshComments()
                    }}
                    className="cursor-pointer block uppercase tracking-wide text-gray-700 text-xs font-bold mb-1 flex justify-end items-center pt-2">Reply <div
                    className="fas fa-chevron-right ml-2"></div></div>
            </div>
        </div>
    );
};