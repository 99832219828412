import * as React from 'react';
import {useState} from "react";
import {projectData} from "../../../../../database/data";
import MicroModal from "react-micro-modal";

type EditProjectProps = {
    projectId,
    currentTitle,
    currentDesc,
    afterSave?: ()=>void
};
export const EditProject: React.FunctionComponent<EditProjectProps> = ({projectId, currentTitle, currentDesc, afterSave}) => {
    const [title, setTitle] = useState(currentTitle)
    const [desc, setDesc] = useState(currentDesc)
    return (
        <div className="my-4 w-full">
            <div className="w-full px-3 my-2">
                <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Title</label>
                <input
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    type="text" name="university" value={title} onChange={e=>setTitle(e.target.value)}/>
            </div>
            <div className="w-full px-3 my-2">
                <label
                    className="block uppercase tracking-wide text-gray-700 text-xs font-bold mb-2">Description</label>
                <textarea
                    className="appearance-none block w-full bg-gray-200 text-gray-700 border border-gray-200 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-gray-500"
                    name="university" value={desc} onChange={e=>setDesc(e.target.value)}/>
                <button
                    onClick={async () => {
                        await projectData.editProject(projectId, title, desc)
                        if(afterSave) afterSave()

                    }}
                    className="m-2 bg-teal-400 hover:bg-teal-500 font-semibold mt-3 text-white py-2 px-4 border border-teal-500 hover:border-transparent rounded-full">
                    <i className="fa fa-save mr-2"></i> Save
                </button>
            </div>
        </div>
    );
};