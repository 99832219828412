import React, {Component, useEffect, useState} from "react";
import {BrowserRouter as Router, Route, Switch} from "react-router-dom";
import AppHome, {AppHomeProps} from "../components/pages/frontPage/appHome";
import ProjectPage from "../components/pages/app/projectList/projectPage";
import Project from "../components/pages/app/projectList/project/project";
import Profile from "../components/pages/app/profile/profile";
import EditProfile from "../components/pages/app/profile/editProfile";
import Messenger from "../components/pages/app/messenger/messenger";
import SkillsEdit from "../components/pages/app/skills/skillsEdit";
import {SkillView} from "../components/pages/app/skills/skillView";
import {SkillList} from "../components/pages/app/skills/skillList/skillList";
import {SkillUsersOverview} from "../components/pages/app/skills/skillUsersOverview/skillUsersOverview";
import {Testimonials} from "../components/pages/app/testimonials/testimonials";
import {userData} from "../database/data";
import LoadIcon from "../components/pages/app/components/loadIcon";
import FullHeightFirstTimeForm from "../components/pages/frontPage/parts/firstTimeForm";

export const LoggedInRouter: React.FunctionComponent<AppHomeProps> = ({}) => {
    const [loading, setLoading] = useState(true);
    const [isFirstTime, setIsFirstTime] = useState<boolean>();
    useEffect(() => {
        userData.getIsFirstTime().then((isFirstTime) => {
            setIsFirstTime(isFirstTime);
            setLoading(false)
        })
    }, [])
    if (loading) return <LoadIcon/>
    if (isFirstTime) return <FullHeightFirstTimeForm/>
    return (
        <Switch>
            <Route exact path="/app" component={AppHome}/>
            <Route exact path="/app/projects" component={ProjectPage}/>
            <Route exact path="/app/projects/project/:id" component={Project}/>
            <Route exact path="/app/skills" component={SkillList}/>
            <Route exact path="/app/skill/:skillId" component={SkillUsersOverview}/>
            <Route exact path="/app/profile/email/:email" component={Profile}/>
            <Route exact path="/app/profile/:email/testimonials" component={Testimonials}/>
            <Route exact path="/app/profile/email/:email/skill/:skillId" component={SkillView}/>
            <Route exact path="/app/profile/editMyProfile" component={EditProfile}/>
            <Route exact path="/app/profile/editMySkills" component={SkillsEdit}/>
            <Route exact path="/app/messenger" component={Messenger}/>
            <Route exact path="/app/messenger/channel/:channelId" component={Messenger}/>
        </Switch>
    );
}

export default LoggedInRouter;