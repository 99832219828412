import * as React from "react";
import {Component} from "react";
import MessengerContact from "./messengerContact";
import MessageContactSelect from "./selectContacts/messageContactSelect";
import {messageData} from "../../../../database/data";
import Loader from "../components/loader";
import LoadIcon from "../components/loadIcon";
import {Notifications} from "./notifications";

export interface MessengerContactsProps {
    isMobile?: boolean
}

export interface MessengerContactsState {
}

class MessengerContacts extends React.Component<MessengerContactsProps,
    MessengerContactsState> {
    state = {channels: [], showContactSelect: false, loading: true, search: ""};

    componentDidMount() {
        messageData.getUserChannelsFromFirebase(channels => {
            this.setState({channels, loading: false});
        });
    }

    render() {
        return (
            <div
                className={`w-${this.props.isMobile ? "full" : "1/3"} max-w-lg bg-gray-100 overflow-x-hidden p-4 flex flex-col`}>
                <div className="text-center font-bold my-4 w-full flex">
                    <input
                        className="w-full shadow-md bg-gray-200 p-4 border rounded-lg"
                        placeholder="Find People and Conversations"
                        onChange={(e) => this.setState({search: e.target.value})}
                        value={this.state.search}
                    />
                    <Notifications/>
                </div>
                {this.state.loading ? (
                    <LoadIcon className="m-auto"/>
                ) : (
                    this.state.channels.length > 0 ?
                        <div className="flex-grow-0 overflow-auto">
                            {this.state.channels.map(channel => {
                                return <MessengerContact search={this.state.search} channelId={channel} key={channel}/>;
                            })}
                        </div> :
                        <div className="flex-shrink flex-grow-0 text-center">
                            No channels yet.
                        </div>
                )}
                <div className={`text-center pt-2 pb-3 ${this.state.channels.length > 0?"mx-auto":"mx-auto"}`}>
                    <div
                        className="rounded-full border p-4 cursor-pointer flex flex-row"
                        onClick={() => this.setState({showContactSelect: true})}><i
                        className="fas fa-plus mt-1 mr-3"></i>Create Chat
                    </div>
                    {this.state.showContactSelect ? (
                        <MessageContactSelect
                            hideContactSelect={() =>
                                this.setState({showContactSelect: false})
                            }
                        />
                    ) : (
                        false
                    )}
                </div>
            </div>
        );
    }
}

export default MessengerContacts;
