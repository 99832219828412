import * as React from 'react';
import * as firebase from "firebase";
import {useEffect, useState} from "react";
import LoadIcon from "../components/loadIcon";
import FileUploader from "react-firebase-file-uploader";
import {userData} from "../../../../database/data";
import authUser from "../../../../auth/auth";

type ProfilePhotoProps = {
  email?: string
  photoURL?: string
}
  ;
export const ProfilePhotoWithUploader: React.FunctionComponent<ProfilePhotoProps> = ({email, photoURL}) => {
  const [photoUrlWithUploader, setPhotoUrlWithUploader] = useState(photoURL)
  const [loadingPhoto, setLoadingPhoto] = useState(false)
  const handleUploadStart = () => {
    setLoadingPhoto(true)
  }

  useEffect(()=>{
    setPhotoUrlWithUploader(photoURL)
  },[photoURL])

  const handleUploadSuccess = async filename => {
    let downloadUrl = await firebase
      .storage()
      .ref("profileImgs")
      .child(filename)
      .getDownloadURL()
    setPhotoUrlWithUploader(downloadUrl)
    await userData.setUserData({photoURL: downloadUrl})
    setLoadingPhoto(false)
  };
  return (
    <div className="flex flex-col justify-center">
      <div>{loadingPhoto ? <LoadIcon/> :
        <div style={{background: `url('${photoUrlWithUploader || "/img/emptyprofile.svg"}')`, backgroundSize: "cover"}}
             className='w-32 mb-6 rounded-full mx-auto h-32'/>}</div>
      {authUser.getEmail() === email ?
        <label
          className="m-2 cursor-pointer bg-transparent hover:bg-red-500 text-red-700 font-semibold hover:text-white py-2 px-4 border border-red-500 hover:border-transparent rounded-full mx-auto inline-block"
          onClick={() => {
          }}>
          <i className="fas fa-pencil-alt mr-3"></i> Edit Picture
          <FileUploader
            hidden
            accept="image/*"
            name="avatar"
            randomizeFilename
            storageRef={firebase.storage().ref("profileImgs")}
            onUploadStart={handleUploadStart}
            onUploadSuccess={handleUploadSuccess}
          />
        </label> : null}
    </div>
  );
};