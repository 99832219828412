import * as React from 'react';
import {useEffect} from 'react';
import {FacebookInstagram} from "../frontPage/parts/facebookInstagram";
import {FullHeightContext} from "../../../routes/Routes";

type AboutUsProps = {
    fullHeightFunction;
};
const FullHeightAboutUs = () => {
    return (
        <FullHeightContext.Consumer>
            {fullHeightFunction => (
                <AboutUs fullHeightFunction={fullHeightFunction}/>
            )}
        </FullHeightContext.Consumer>
    );
};
const AboutUs: React.FunctionComponent<AboutUsProps> = ({fullHeightFunction}) => {
    useEffect(() => {
        fullHeightFunction(true);
        return () => fullHeightFunction(false);
    }, [])
    return (
        <div
            className="h-full flex flex-col overflow-auto"
            style={{
                backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`,
                backgroundSize: "cover"
            }}
        >
            <div className="flex-grow w-full md:w-3/5 py-6 text-center m-auto px-0 md:px-6 container">
                <div
                    className="overflow-hidden shadow-lg"
                    style={{backgroundColor: "#ec6763", borderRadius: "4em"}}
                >
                    <div
                        className="rounded overflow-hidden shadow-lg bg-white text-black my-6 mx-0 md:mx-12 py-12 px-4 md:px-12 text-xl"
                        style={{textAlign: "left", borderRadius: "6em"}}
                    >
                        <div className="text-center text-4xl font-bold">About Us</div>
                        <div className="text-center mt-4 block">Actea is a platform developed by students, for students. At
                            university, we are surrounded by a plethora of unique talent, whether that be across
                            multiple academic disciplines or skills mastered beyond our chosen degrees. Despite an
                            abundance of readily accessible talent and ideas for projects, no targeted platform to
                            connect these students exists.
                            <br/><br/>
                            At Actea, our platform strives to achieve this goal. Students are able to both freelance out
                            their own skills, as well as hire other students with skills. In addition to this, those
                            wishing to fulfil their entrepreneurial cravings or work on projects to gain valuable
                            experience are now able either find ongoing projects, or the necessary skills to bring their
                            ideas to life.
                            <br/><br/>
                            With Actea, finding the right skills, collaborators and co-founders not only became easier, but more accessible.
                        </div>
                    </div>
                </div>
            </div>
            <FacebookInstagram/>
        </div>
    );
};

export default FullHeightAboutUs