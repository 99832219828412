import * as React from "react";
import {SearchAlignment} from "./projectList";
import ProjectListImgTop from "./projectListImgTop";

export interface ProjectPageProps {
}

export interface ProjectPageState {
    projectListKey: number
}

class ProjectPage extends React.Component<ProjectPageProps, ProjectPageState> {
    state = {projectListKey:0}
    render() {
        return (
            <div className="container mx-auto px-3">
                <ProjectListImgTop key={this.state.projectListKey} searchAlignment={SearchAlignment.Center}/>
            </div>
        );
    }
}

export default ProjectPage;
