import React, {useState, useEffect} from "react";
import {skillsData} from "../../../../database/data";
import LoadIcon from "../components/loadIcon";
import {Link} from "react-router-dom";
import SkillAndUsersCard from "./skillList/skillAndUsersCard";
import {Skill} from "../../../../database/skillsData";
import SingleSkillCardForUser from "./singleSkillCardForUser";

export interface SkillsDisplayProps {
    email: string;
    editing?: boolean;
    newSkills?: Skill[];
    isLoading?: boolean;
}

const SkillsDisplay: React.FunctionComponent<SkillsDisplayProps> = ({email, editing = false, newSkills, isLoading}) => {
    return (
        <>
            {isLoading ? <LoadIcon/> : false}
            {[ ...(newSkills || [])].map(skill => (
                <SingleSkillCardForUser key={skill.id} skill={skill} editing={editing} email={email}/>
            ))}
        </>
    );
};

export default SkillsDisplay;
