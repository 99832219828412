import * as React from 'react';
import {Link} from "react-router-dom";

type LogoAndTextProps = {};
export const LogoAndText: React.FunctionComponent<LogoAndTextProps> = ({}) => {
    return (
        <Link to="/" className="flex  items-center">
            <img className="h-10 mr-6" src={process.env.PUBLIC_URL + '/img/logo/logo.svg'}/>
            <img className="h-6" src={process.env.PUBLIC_URL + '/img/logo/acteaWriting.svg'}/>
        </Link>
    );
};