import {data, projectData, userData} from "./data";
import authUser from "../auth/auth";
import {AgoliaSearchResult, notEmpty} from "./searchTypes";
import {Likes} from "./likesData";
import * as firebase from "firebase";
import {ProjectsFilter} from "../components/pages/app/projectList/projectListImgTop";

type Timestamp = firebase.firestore.Timestamp;

export type FirebaseProject = {
    content: string,
    time: Timestamp,
    title: string,
    image?: string
    user_email: string,
    id: string,
    likes?: Likes
}

type ProjectSearchResult = {
    id: string
}[]

export enum ProjectSortType {
    TIME = "time",
    LIKES = "likeCount"
}

class ProjectData {
    async getProject(id: string) {
        let project = await data.getDatabase()
            .collection("projects")
            .doc(id)
            .get()
        return {id: project.id, ...project.data()}
    }

    async getLatestPosts(number: Number, email?: string, sort: ProjectSortType = ProjectSortType.TIME,
                         projectsFilter: ProjectsFilter = ProjectsFilter.NONE) {
        let query = data.getDatabase()
            .collection("projects")
        let userEmail = authUser.getEmail()
        if (sort && projectsFilter !== ProjectsFilter.LIKED) {
            query = query.orderBy(sort.toString(), "desc")
        }
        query = query.limit(number);
        if (email) {
            query = query.where("user_email", "==", email);
        }
        if (projectsFilter === ProjectsFilter.LIKED && userEmail) {
            query = query.where(new firebase.firestore.FieldPath(`likes`, userEmail), "==", true)
        } else if (projectsFilter === ProjectsFilter.SUBSCRIPTION && userEmail) {
            let unfilteredSubscriptions = (await data
                .getDatabase()
                .collection("users")
                .doc(userEmail).get()).data().subscriptions
            let filteredSubscriptions = userData.getActiveSubscriptionsFromSubscriptions(unfilteredSubscriptions)
            if (!filteredSubscriptions || filteredSubscriptions.length === 0) return []
            query = query.where("user_email", "in", filteredSubscriptions)
        }
        let posts = await query.get()
        return this.processFirebaseList(posts);
    }

    async getPost(id: string): Promise<FirebaseProject | undefined> {
        let post = await data.getDatabase()
            .collection("projects").doc(id).get()
        return {id: post.id, ...post.data()}
    }

    async getPostSearchResults(search: string): Promise<FirebaseProject[]> {
        let queryResult: AgoliaSearchResult = await (await fetch(
                `https://us-central1-socialmedia-9fc35.cloudfunctions.net/searchRequest?searchQuery=
                    ${search}&indexName=projects`)
        ).json()
        const ids = queryResult.content.hits.map((project) => ({
            id: project.objectID
        }))
        return (await Promise.all(ids.map(async idOfPost => {
            return await projectData.getPost(idOfPost.id)
        }))).filter(notEmpty)
    }

    processFirebaseList(data: any) {
        let projects: Array<FirebaseProject> = [];
        data.forEach((i: any) => {
            data = i.data();
            data["id"] = i.id;
            projects.push(data);
        });
        return projects;
    }

    async addNewProject(title: string, content: string, imgLink: string) {
        await data.getDatabase().collection("projects").add({
            title: title,
            content: content,
            image: imgLink,
            time: new Date(),
            user_email: authUser.getEmail(),
            user_name: authUser.getName()
        });
    }

    async editProject(projectId: string, title: string, content: string) {
        if (projectId) {
            await data.getDatabase().collection("projects").doc(projectId).set({
                title: title,
                content: content
            }, {merge: true});
            return true
        }
        return false

    }

    async deleteProject(projectId: string | undefined) {
        if (projectId) {
            await data.getDatabase().collection("projects").doc(projectId).delete();
            return true
        }
        return false

    }
}

for (const key of Object.getOwnPropertyNames(ProjectData.prototype)) {
    const old = ProjectData.prototype[key];
    if (old.constructor.name === "AsyncFunction") {
        ProjectData.prototype[key] = async function (...args) {
            // console.log('Fetching Data', key);
            return await old.call(this, ...args);
        };
    } else {
        ProjectData.prototype[key] = function (...args) {
            // console.log('Fetching Data', key);
            return old.call(this, ...args);
        };
    }

}


export default ProjectData