import React, { Component } from 'react';

class Home extends Component {
    render() {
      return (
        <div className="jumbotron">
            <h1 className="display-3">Home!</h1>
        </div>
      );
    }
  }

export default Home;