import * as React from "react";
import {messageData, userData} from "../../../../database/data";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import {FirebaseProject} from "../../../../database/projectData";
import {LikeType} from "../../../../database/likesData";
import ProfileImg from "../../app/components/profileImg";
import {LikeCounter} from "../../app/components/likeCounter";
import {ProfileImgAndName} from "../../app/components/profileImgAndName";
import authUser from "../../../../auth/auth";

export interface ProjectCardProps {
    data: FirebaseProject;
}

export interface ProjectCardState {
}

class ProjectCardImgTop extends React.Component<ProjectCardProps, ProjectCardState> {
    state = {name: null, img: "load", likes: this.props.data.likes || {}};

    componentDidMount() {
        userData.getUserFromEmail(this.props.data.user_email).then(this.setUserDetail);
    }

    setUserDetail = userDataInput => {
        let name = userData.getUserName(userDataInput);
        this.setState({name: name, img: userDataInput.photoURL});
    };

    message = async () => {
        let myEmail: string | undefined = authUser.getEmail();
        let receiverEmail: string | undefined = this.props.data.user_email;
        if (myEmail && receiverEmail) {
            let newChat = await messageData.createNewChannel([myEmail, receiverEmail]);
            window.location.replace(`/app/messenger/channel/${newChat}`);
        }
    };


    render() {
        return (<div
                className="max-w-sm cursor-pointer w-full flex flex-col m-2 scale-on-hover shadow-md overflow-auto break-word border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded flex-shrink-0 ">
                {this.props.data.image ? (
                    <Link
                        to={"/app/projects/project/" + this.props.data.id}>
                        <div
                            className="h-48   flex-none bg-cover rounded-t text-center overflow-hidden bg-center"
                            style={{
                                backgroundImage: `url('${this.props.data.image}')`
                            }}
                        />
                    </Link>
                ) : (
                    <Link
                        to={"/app/projects/project/" + this.props.data.id}>
                        <div
                            className="h-48 flex-none bg-cover rounded-t text-center overflow-hidden bg-center"
                            style={{
                                backgroundImage: `url('/img/logo/noPhoto.jpg')`
                            }}
                        />
                    </Link>
                )}

                <div className="p-4 flex-grow break-all">
                    <Link to={"/app/projects/project/" + this.props.data.id}>
                        <div className="mb-3">
                            <div className="text-black font-bold text-xl mb-2 break-all truncate">
                                {this.props.data.title}
                            </div>
                            <p className="text-grey-darker text-base break-all truncate">
                                {this.props.data.content}
                            </p>
                        </div>
                    </Link>
                    <div className="flex items-end">
                        <div className="flex flex-grow justify-between items-center">
                            <ProfileImgAndName email={this.props.data.user_email}
                                               time={this.props.data.time.toDate()}/>
                            <div className="flex items-center">
                                <LikeCounter className="ml-5 mr-2"
                                             initLikes={this.props.data.likes || {}}
                                             id={this.props.data.id} type={LikeType.PROJECT}/>
                                <i onClick={this.message}
                                   className="fas fa-comments">
                                </i>
                            </div>

                        </div>
                    </div>

                </div>

            </div>
        );
    }
}

export default ProjectCardImgTop;
