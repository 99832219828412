import * as React from "react";
import {Component} from "react";
import {Link} from "react-router-dom";
import LoggedOutNav from "./loggedOutNav";
import LoggedInNav from "./loggedInNav";
import {isMobile} from "react-device-detect";
import posed from "react-pose";
import {LogoAndText} from "./logoAndText";
export interface NavProps {
    loggedIn: Boolean;
}

export interface NavState {
}

const NavOptions = posed.div({
    visible: {height: "auto"},
    hidden: {height: 0}
});

class Nav extends React.Component<NavProps, NavState> {
    state = {hidden: "hidden", isMobile: false};

    toggleNav = () => {
        if (this.state.hidden) this.setState({hidden: ""});
        else this.setState({hidden: "hidden"});
    };
    closeNav = () => {
        if (!this.state.hidden) this.setState({hidden: "hidden"});
    };

    render() {
        return (
            <nav onClick={this.closeNav}
                 className="flex items-center top-0 z-50 justify-between flex-wrap bg-white shadow-lg sticky px-6 py-3 border-t-4 border-primary">
                <LogoAndText/>
                <div onClick={this.toggleNav} className="block md:hidden">
                    <button
                        className="flex items-center px-3 py-2 border rounded text-black border-teal-light hover:bg-red-100 hover:border-red-100">
                        <svg
                            className="fill-current text-black h-3 w-3"
                            viewBox="0 0 20 20"
                            xmlns="http://www.w3.org/2000/svg"
                        >
                            <title>Menu</title>
                            <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"/>
                        </svg>
                    </button>
                </div>
                {/* First one is Mobile Navbar and second is desktop navbar. s*/}
                <NavOptions
                    className={`w-full md:flex  block flex-grow md:items-center md:w-auto overflow-hidden visible md:hidden ml-3`}
                    pose={this.state.hidden ? 'hidden' : 'visible'}
                >
                    {this.props.loggedIn ? <LoggedInNav/> : <LoggedOutNav/>}
                </NavOptions>
                <div
                    className="w-full md:flex  block flex-grow md:items-center md:w-auto overflow-hidden hidden md:visible ml-3"
                >
                    {this.props.loggedIn ? <LoggedInNav/> : <LoggedOutNav/>}
                </div>
            </nav>
        );
    }
}

export default Nav;
