import * as React from "react";
import {projectData} from "../../../../database/data";
import LoadIcon from "../components/loadIcon";
import {debounce} from "../../../../database/utils";
import ProjectCardImgTop from "../../frontPage/components/projectCardImgTop";
import Select from 'react-select';
import {ProjectSortType} from "../../../../database/projectData";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faLayerGroup} from "@fortawesome/free-solid-svg-icons/faLayerGroup";
import CreateNewProject from "./createNewProject";

export interface ProjectListProps {
    user?: string;
    searchAlignment: SearchAlignment;
}

export interface ProjectListState {
    projects: Array<Object>;
    hasMore: boolean;
    loading: boolean;
    items: number;
    loadingSearch: boolean;
    loadingMore: boolean;
    sortType: ProjectSortType;
    currentFilter: ProjectsFilter;
}

export enum SearchAlignment {
    Left = "mr-auto",
    Right = "ml-auto",
    Center = "mx-auto"
}

export enum ProjectsFilter {
    SUBSCRIPTION,
    LIKED,
    NONE
}

const getStringForSortType = (sortType: ProjectSortType): string => {
    switch (sortType) {
        case ProjectSortType.TIME:
            return "Time";
        case ProjectSortType.LIKES:
            return "Likes"
    }
}


class ProjectListImgTop extends React.Component<ProjectListProps, ProjectListState> {
    state = {
        projects: [],
        hasMore: true,
        loading: true,
        items: 0,
        loadingSearch: false,
        loadingMore: false,
        sortType: ProjectSortType.TIME,
        currentFilter: ProjectsFilter.NONE
    };

    componentDidMount() {
        this.loadMoreProjects();
    }

    loadMoreProjects = (add: number = 6) => {
        console.log(this.state.currentFilter)
        projectData.getLatestPosts(this.state.items + add, this.props.user,
            this.state.sortType, this.state.currentFilter)
            .then(this.renderData);
        this.setState({items: this.state.items + add, loadingMore: true})
    };
    renderData = (data: Object[]) => {
        this.setState({
            projects: data,
            loading: false,
            loadingSearch: false,
            loadingMore: false,
            hasMore: data.length === this.state.items
        });
    };

    handleOnSearch = debounce((searchString) => {
        this.setState({items: 6}, () => {
            if (searchString.length > 0) {
                projectData.getPostSearchResults(searchString).then((data) => {
                    this.renderData(data)
                })
            } else {
                this.setState({hasMore: true})
                projectData.getLatestPosts(this.state.items, this.props.user ? this.props.user : "",
                    this.state.sortType, this.state.currentFilter)
                    .then(this.renderData);
            }
        })
    }, 500)


    render() {
        if (this.state.loading) return <LoadIcon/>
        return (
            <div className="mt-5 mx-auto">
                <div
                    className="rounded-lg mx-auto rounded mt-6 items-center flex  flex-col md:flex-row"
                    style={{
                        backgroundImage: `url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`
                    }}
                >
                    <div className="flex sm:ml-8 items-center pt-5 md:pt-0">
                        <FontAwesomeIcon
                            icon={faLayerGroup}
                            className="mr-4 text-white sm:text-5xl text-3xl"
                        />
                        <h1 className="text-white sm:text-6xl text-3xl neue leading-none mt-1">Projects</h1>
                    </div>
                    <div className="flex items-center flex-wrap px-3 md:px-12  py-4 w-full">
                        <input type="text"
                               className={`border-2 py-2 px-4 m-3 block rounded rounded-lg w-full`}
                               name="title"
                               onChange={(e) => {
                                   this.handleOnSearch(e.target.value)
                                   this.setState({loadingSearch: true})
                               }}
                               placeholder={'Search Here...'}/>
                        <Select placeholder="Sort By"
                                onChange={selected => {
                                    this.setState({sortType: ProjectSortType[selected.value]}, () => {
                                        this.loadMoreProjects(0)
                                    })
                                }}
                                options={Object.keys(ProjectSortType).map(type => ({
                                    value: type,
                                    label: getStringForSortType(ProjectSortType[type])
                                }))}
                                className={`w-64 m-3`}/>
                        <div className="flex justify-center items-center m-3">
                            <input className="mr-2 leading-tight w-6 h-6" type="checkbox"
                                   checked={this.state.currentFilter === ProjectsFilter.SUBSCRIPTION}
                                   onChange={(e) => {
                                       this.setState({currentFilter: e.target.checked ? ProjectsFilter.SUBSCRIPTION : ProjectsFilter.NONE}, () => {
                                           this.loadMoreProjects(0)
                                       })
                                   }}/>
                            <div className="text-white">Show Subscribed Only</div>
                        </div>
                        <div className="flex justify-center items-center m-3">
                            <input className="mr-2 leading-tight w-6 h-6" type="checkbox"
                                   checked={this.state.currentFilter === ProjectsFilter.LIKED}
                                   onChange={(e) => {
                                       this.setState({currentFilter: e.target.checked ? ProjectsFilter.LIKED : ProjectsFilter.NONE}, () => {
                                           this.loadMoreProjects(0)
                                       })
                                   }}/>
                            <div className="text-white">Show Liked Only</div>
                        </div>

                    </div>
                </div>
                <CreateNewProject projectCreated={() => this.loadMoreProjects(0)}/>
                {this.state.loadingSearch ?
                    <LoadIcon/> :
                    <>
                        <div className="py-3 flex justify-center flex-wrap">
                            {this.state.projects.map((data) => (
                                <ProjectCardImgTop key={data["id"]} data={data}/>
                            ))}

                        </div>
                        {this.state.hasMore ? <button
                            onClick={() => this.loadMoreProjects()}
                            className="bg-blue-700 hover:bg-blue-dark text-white font-bold py-2 px-4 m-auto block rounded-full my-3"
                        >
                            {this.state.loadingMore ? <span>Loading...</span> : <span>Load More</span>}
                        </button> : false}</>
                }

            </div>

        )
    }
}

export default ProjectListImgTop;
