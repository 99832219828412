import * as React from 'react';
import {Component} from 'react';
import {startFirebaseUI} from './startFirebaseUI';
import {FullHeightContext} from "../../../routes/Routes";
import {FacebookInstagram} from "../frontPage/parts/facebookInstagram";

export interface LoginPageProps {
    fullHeightFunction;
}

export interface LoginPageState {
}

const FullHeightLoginPage = ({match}) => {
    return <FullHeightContext.Consumer>
        {fullHeightFunction => <LoginPage fullHeightFunction={fullHeightFunction}/>}
    </FullHeightContext.Consumer>
}

class LoginPage extends React.Component<LoginPageProps, LoginPageState> {
    state = {}

    componentDidMount() {
        this.props.fullHeightFunction(true)
        startFirebaseUI('#firebase-login')
    }

    componentWillUnmount(): void {
        this.props.fullHeightFunction(false)
    }

    render() {

        const style = {
            fontFamily: 'Poppins',
            fontSize: '45'
        }

        return (<div className="h-full flex flex-col " style={{
            backgroundImage: `linear-gradient(rgba(0,0,0,0.5), rgba(0,0,0,0.5)),url(${process.env.PUBLIC_URL}/img/uBE1Qx6.jpg)`,
            backgroundSize: "cover"
        }}>
            <div style={style} className="text-center mt-6 mb-3 text-white text-xl">Please Login <b>With A Student Account</b>   to Begin:</div>
            <div id="firebase-login" className="flex-grow"></div>
            <FacebookInstagram/>
        </div>);
    }
}

export default FullHeightLoginPage;