import * as React from "react";
import { Link } from "react-router-dom";

export interface LoggedOutNavProps {}

export interface LoggedOutNavState {}

class LoggedOutNav extends React.Component<
  LoggedOutNavProps,
  LoggedOutNavState
> {
  render() {
    return (
      <React.Fragment>
        <div className="text-sm md:flex-grow">

        </div>
        <div>
          <Link to="/login">
            <a className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4">
              Login/Sign Up
            </a>
          </Link>
        </div>
      </React.Fragment>
    );
  }
}

export default LoggedOutNav;
