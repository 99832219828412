import * as React from "react";
import {Link} from "react-router-dom";
import authUser from "../../auth/auth";
import {userData} from '../../database/data';
import store from 'store';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome'

export interface LoggedInNavProps {
}

export interface LoggedInNavState {
}

class LoggedInNav extends React.Component<LoggedInNavProps, LoggedInNavState> {
    logout = () => {
        authUser.signOut();
    }

    render() {
        return (
            <React.Fragment>
                <div className="text-sm md:flex-grow">
                </div>
                <div>
                    <Link
                        to="/app"
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        Home
                    </Link>
                    <Link
                        to={`/app/skills`}
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        Skills
                    </Link>
                    <Link
                        to={`/app/projects`}
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        Projects
                    </Link>
                    <Link
                        to={`/app/messenger`}
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        Messages
                    </Link>
                    <Link
                        to={`/app/profile/email/${authUser.getEmail()}`}
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        My Profile
                    </Link>
                    <Link
                        to="#"
                        onClick={this.logout}
                        className="block mt-4 md:inline-block md:mt-0 text-black hover:text-red-800 p-2 rounded hover:bg-red-100 mr-4"
                    >
                        Logout
                    </Link>
                </div>
            </React.Fragment>
        );
    }
}

export default LoggedInNav;
