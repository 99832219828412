import * as React from "react";
import {userData} from "../../../../database/data";
import Moment from "react-moment";
import {Link} from "react-router-dom";
import ProfileImg from "../components/profileImg";
import {FirebaseProject} from "../../../../database/projectData";
import {LikeCounter} from "../components/likeCounter";
import {LikeType} from "../../../../database/likesData";
import {ProfileImgAndName} from "../components/profileImgAndName";

export interface ProjectCardProps {
    data: FirebaseProject;
}

export interface ProjectCardState {
}

class ProjectCard extends React.Component<ProjectCardProps, ProjectCardState> {
    state = {name: null, img: "load", likes: this.props.data.likes || {}};

    componentDidMount() {
        userData.getUserFromEmail(this.props.data.user_email).then(this.setUserDetail);
    }

    setUserDetail = userDataInput => {
        let name = userData.getUserName(userDataInput);
        this.setState({name: name, img: userDataInput.photoURL});
    };


    render() {
        return (<div
                className="max-w-2xl cursor-pointer w-full flex mx-auto my-5 scale-on-hover shadow-md break-word border-r border-b border-l border-grey-light lg:border-l-0 lg:border-t lg:border-grey-light bg-white rounded">
                {this.props.data.image ? (
                    <Link
                        to={"/app/projects/project/" + this.props.data.id}>
                        <div
                            className="h-48 lg:h-full lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden bg-center"
                            style={{
                                backgroundImage: `url('${this.props.data.image}')`
                            }}
                        />
                    </Link>
                ) : (
                    false
                )}
                <div className="flex flex-col">
                    <div className="p-6 flex-grow">
                        <Link className="block" to={"/app/projects/project/" + this.props.data.id}>
                            <div className="text-black font-bold text-xl mb-2 break-words">
                                {this.props.data.title}
                            </div>
                            <p className="text-grey-darker text-base break-all three-line-max">
                                {this.props.data.content}
                            </p>
                        </Link>
                    </div>
                    <div className="flex justify-between p-6 pt-0">
                        <ProfileImgAndName email={this.props.data.user_email} time={this.props.data.time.toDate()}/>
                        <LikeCounter className="mr-4 mb-3" initLikes={this.props.data.likes || {}}
                                     id={this.props.data.id}
                                     type={LikeType.PROJECT}/>
                    </div>
                </div>
            </div>
        );
    }
}

export default ProjectCard;

// {this.props.data.image ? (
//   <div
//     className="h-48 lg:h-auto lg:w-48 flex-none bg-cover rounded-t lg:rounded-t-none lg:rounded-l text-center overflow-hidden bg-center"
//     style={{
//       backgroundImage: `url('${this.props.data.image}')`
//     }}
//   />
// ) : (
//   false
// )}
// <div className="p-4">
//   <div className="mb-8">
//     <div className="text-black font-bold text-xl mb-2">
//       {this.props.data.title}
//     </div>
//     <p className="text-grey-darker text-base">
//       {this.props.data.content}
//     </p>
//   </div>
//   <div className="flex items-center">
//     <div className="text-sm">
//       <p className="text-black leading-none">{this.state.name}</p>
//       <Moment fromNow>{this.props.data.time.toDate()}</Moment>
//     </div>
//   </div>
// </div>
